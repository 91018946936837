<template>
  <div class="home">
    <div class="container-header">
      <div>
        <div class="container-top">
          <div class="c-top-button flex-between">
            <img src="@/assets/images/home/logo.webp" alt="" style="width: 62px;height: 36px;">
            <div class="rt">
              <span @click="RouterTo('/introduce')">关于极赫</span>
              <span @click="openHerf">肌肤检测</span>
            </div>
          </div>
          <div class="t-f-button">
            <span @click="RouterTo('/introduce')"></span>
          </div>
        </div>
      </div>
    </div>
    <div class="container-detail">
      <div class="container-con">
        <img src="@/assets/images/home/title_one.webp" alt="" style="width: 624px;height: 136px;display: block;margin: 80px auto;">
        <img src="@/assets/images/home/stay_up.webp" alt=""  style="width: 1200px;height: 548px;display: block;margin: 0 auto 40px;">
        <ul class="flex-between">
          <li v-for="item in productInfomation" :key="item" @click="RouterTo('/product?id=' + item.id)">
            <img :src="item.url" alt="" style="width: 224px;height: 224px;display: block;margin: 0 auto;">
            <div>{{ item.name }}</div>
            <div class="des">{{ item.title1 }} </div>
            <div class="des">{{ item.title2 }}</div>
            <div class="btn" >了解详情</div>
          </li>
        </ul>
      </div>
      <div class="container-scancode">
        <div class="content">
          <div>
            <img src="@/assets/images/home/guangbiao_pic.webp" alt="" style="width: 367px;height: 66px;" class="light-image">
            <img src="@/assets/images/home/code-scan.png" alt="" style="width: 215px;height: 215px;" class="code-image">
          </div>
        </div>
      </div>
      <div class="personal-advise">
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script setup>
import Footer from '../../components/footer.vue';
import { productInfomation } from '@/assets/data/product';
import {
  useRouter
} from 'vue-router';
const Router = useRouter();
const RouterTo =(url) => {
  Router.push(url)
}

const openHerf = () => {
  window.scrollTo(0, 2044);
}
</script>


<style scoped lang="scss">
@keyframes moveStepBottom {
  50% {
    top: 515px;
  }
  100% {
    top: 190px;
  }
}
.home{
  min-width: 1200px;
  background: #fff;
}
.container-header{
  background: linear-gradient(124.99deg, rgba(226, 203, 178, 1) 0%, rgba(229, 207, 183, 1) 100%);
  &>div{
    height: 720px;
    margin: 0 auto;
    background-image: url('./../../assets/images/home/home-top.webp');
    background-position: center center;
    background-size: auto 100% ;
    background-repeat: no-repeat;
  }
  .container-top{
    height: 100px;
    border-bottom: 1px solid rgba(173, 141, 114, 0.32);
  }
  .t-f-button{
    width: 1200px;
    height: 483px;
    margin: 0 auto;
    position: relative;
    &>span{
      cursor: pointer;
      width: 169px;
      height: 52px;
      position: absolute;
      bottom: 0;
      right: 372px;
    }
  }
  .c-top-button{
    width: 1200px;
    margin: 0 auto;
    height: 100%;
    align-items: center;
    .rt{
      font-size: 16px;
      span{
        margin-right: 96px;
        color: #212121;
        font-weight: 400;
        cursor: pointer;
      }
    }
  }
  
}
.container-con{
  width: 1200px;
  overflow: hidden;
  padding-bottom: 80px;
  margin: 0 auto;
  ul{
    li{
      cursor: pointer;
      .btn{
        width: 100px;
        height: 36px;
        border-radius: 4px;
        display: block;
        margin: 0 auto;
        background-color: #FFF;
        border: 1px solid #000;
        line-height: 34px;
        font-size: 14px;
        margin-top: 20px;
        transition: 1s;
        &:active,
        &:hover{
          opacity: .6;
        }
      }
      div{
        font-size: 18px;
        color: #222;
        text-align: center;
        font-weight: 400;
      }
      .des{
        color: #888;
        font-size: 14px;
        line-height: 21px;
        margin: 7px 0 2px;
      }
    }
  }
}
.container-scancode{
  background: linear-gradient(161.36deg, rgba(253, 247, 232, 1) 0%, rgba(241, 227, 211, 1) 100%);
  &>div{
    height: 750px;
    background-image: url('./../../assets/images/home/skin_pic.webp');
    background-position: center center;
    background-size: auto 100% ;
    background-repeat: no-repeat;
    &>div{
      width: 1200px;
      margin: 0 auto;
      position: relative;
      .light-image{
        position: absolute;
        top: 190px;
        left: 147px;
        animation: moveStepBottom 4s infinite linear;
      }
      .code-image{
        position: absolute;
        top: 383px;
        right: 264px;
      }
    }
  }
}
.personal-advise{
  height: 744px;
  background-image: url('@/assets/images/home/guwen_pic.webp');
  background-size: auto 100%;
  background-position: center center;
  background-repeat: no-repeat;
}
</style>
